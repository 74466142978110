import React from 'react'
import { sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ wim }) => {
  return (
    <div className={styles.wim}>
      <div className={styles.wrap}>
        <div className={styles.info}>
          <h2 className={styles.title}>{wim.title}</h2>
          <p className={styles.desc}>{wim.description}</p>
          <p className={styles.include}>{wim.includeTitle}</p>
          <ul className={styles.steps}>
            {wim.items &&
              wim.items.map((item, index) => (
                <li className={styles.stepsItem} key={index}>
                  {item}
                </li>
              ))}
          </ul>
        </div>
        <div className={styles.image}>
          <img className={styles.imageSrc} src={sanityImageUrl(wim.image)} alt={wim.image?.caption} />
        </div>
      </div>
    </div>
  )
}
