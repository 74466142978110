import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import ProductSchema from '../components/Shared/Schema/Product'
import Header from '../components/HomePageV3/Header'
import Doctors from '../components/HomePageV3/Doctors'
import Protocols from '../components/HomePageV3/Protocols'
import Science from '../components/HomePageV3/Science'
import Reviews from '../components/HomePageV3/Reviews'
import Resources from '../components/HomePageV3/Resources'
import Faq from '../components/HomePageV3/Faq'
import Wim from '../components/HomePageV3/Wim'

export default ({ pageContext: { data } }) => {
  return (
    <Layout data={data}>
      <SEO {...data.seo} />
      {data.productSchemas && data.productSchemas.map(schema => <ProductSchema {...schema} />)}
      <Header header={data.header} />
      <Doctors science={data.science} />
      <Protocols info={data.protocols} protocols={data.settings?.protocols} />
      <Science science={data.science} />
      <Reviews reviews={data.reviews} />
      <Wim wim={data.wim} />
      <Resources resources={data.resources} />
      <Faq faq={data.faq} />
    </Layout>
  )
}
