import React from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import { srcSetProps, sanityFileUrl, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ science }) => {
  return (
    <div className={styles.science} id="science">
      <div className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.title}>{science.title}</h2>
          <p className={styles.desc}>{science.description}</p>
        </div>

        <div className={styles.nav}>{science.navTitle1}</div>
        <div className={styles.steps}>
          {science.items &&
            science.items.map(item => (
              <div className={styles.step} key={item._key}>
                <img className={styles.image} src={sanityImageUrl(item.image)} alt={item.image?.caption} />
                <p className={styles.stepTitle}>{item.title}</p>
                <p className={styles.stepDesc}>{item.description}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
